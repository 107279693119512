export const black = "#000000";
export const grayDarker = "#333333";
export const grayDark = "#555555";
export const gray = "#999999";
export const grayLight = "#AAAAAA";
export const grayLighter = "#DDDDDD";
export const white = "#FFFFFF";
export const blue = "#005A88";
export const blueDark = "#2C3747";
export const green = "#76A005";
export const red = "#CF5044";
export const yellow = "#FFC40D";
export const orange = "#BF4F04";
export const pink = "#C3325F";
export const purple = "#7A43B6";
export const bodyBackground = "#FFFFFF";
export const textColor = "#444444";
export const linkColor = "#005987";
export const linkColorHover = "#678D00";
export const sansFontFamily = "'Helvetica Neue',Helvetica,Arial,sans-serif";
export const serifFontFamily = "Georgia,'Times New Roman',Times,serif";
export const monoFontFamily = "Monaco,Menlo,Consolas,'Courier New',monospace";
export const baseFontSize = "14px";
export const baseFontFamily = "inherit";
export const baseLineHeight = "20px";
export const altFontFamily = "Georgia,'Times New Roman',Times,serif";
export const headingsFontFamily = "inherit";
export const headingsFontWeight = "bold";
export const headingsColor = "inherit";
export const fontSizeLarge = "18px";
export const fontSizeSmall = "12px";
export const fontSizeMini = "10.5px";
export const paddingLarge = "11px 19px";
export const paddingSmall = "2px 10px";
export const paddingMini = "0 6px";
export const baseBorderRadius = "4px";
export const borderRadiusLarge = "6px";
export const borderRadiusSmall = "3px";
export const tableBackground = "#000000";
export const tableBackgroundAccent = "#F9F9F9";
export const tableBackgroundHover = "#F5F5F5";
export const tableBorder = "#DDDDDD";
export const btnBackground = "#FFFFFF";
export const btnBackgroundHighlight = "rgb(230, 230, 230)";
export const btnBorder = "#CCCCCC";
export const btnPrimaryBackground = "#0088CC";
export const btnPrimaryBackgroundHighlight = "rgb(0, 68, 204)";
export const btnInfoBackground = "#5BC0DE";
export const btnInfoBackgroundHighlight = "#2F96B4";
export const btnSuccessBackground = "#62C462";
export const btnSuccessBackgroundHighlight = "#51A351";
export const btnWarningBackground = "rgb(251, 180, 81)";
export const btnWarningBackgroundHighlight = "#F89406";
export const btnDangerBackground = "#EE5F5B";
export const btnDangerBackgroundHighlight = "#BD362F";
export const btnInverseBackground = "#444444";
export const btnInverseBackgroundHighlight = "#222222";
export const inputBackground = "#FFFFFF";
export const inputBorder = "#CCCCCC";
export const inputBorderRadius = "4px";
export const inputDisabledBackground = "#EEEEEE";
export const formActionsBackground = "#F5F5F5";
export const inputHeight = "30px";
export const dropdownBackground = "#FFFFFF";
export const dropdownBorder = "rgba(0, 0, 0, 0.2)";
export const dropdownDividerTop = "#E5E5E5";
export const dropdownDividerBottom = "#FFFFFF";
export const dropdownLinkColor = "#333333";
export const dropdownLinkColorHover = "#FFFFFF";
export const dropdownLinkColorActive = "#FFFFFF";
export const dropdownLinkBackgroundActive = "#0088CC";
export const dropdownLinkBackgroundHover = "#0088CC";
export const zindexDropdown = 1000;
export const zindexPopover = 1010;
export const zindexTooltip = 1030;
export const zindexFixedNavbar = 1030;
export const zindexHeader = 1060;
export const zindexModalBackdrop = 1070;
export const zindexModal = 1080;
export const zindexAboveModal = 1090;
export const iconSpritePath = "../img/glyphicons-halflings.png";
export const iconWhiteSpritePath = "../img/glyphicons-halflings-white.png";
export const placeholderText = "#999999";
export const hrBorder = "#EEEEEE";
export const horizontalComponentOffset = "180px";
export const wellBackground = "#F5F5F5";
export const navbarCollapseWidth = "979px";
export const navbarCollapseDesktopWidth = "980px";
export const navbarHeight = "40px";
export const navbarBackgroundHighlight = "#FFFFFF";
export const navbarBackground = "rgb(242, 242, 242)";
export const navbarBorder = "rgb(212, 212, 212)";
export const navbarText = "#777777";
export const navbarLinkColor = "#777777";
export const navbarLinkColorHover = "#333333";
export const navbarLinkColorActive = "#555555";
export const navbarLinkBackgroundHover = "#000000";
export const navbarLinkBackgroundActive = "rgb(230, 230, 230)";
export const navbarBrandColor = "#777777";
export const navbarInverseBackground = "#111111";
export const navbarInverseBackgroundHighlight = "#222222";
export const navbarInverseBorder = "#252525";
export const navbarInverseText = "#999999";
export const navbarInverseLinkColor = "#999999";
export const navbarInverseLinkColorHover = "#FFFFFF";
export const navbarInverseLinkColorActive = "#FFFFFF";
export const navbarInverseLinkBackgroundHover = "#000000";
export const navbarInverseLinkBackgroundActive = "#111111";
export const navbarInverseSearchBackground = "rgb(82, 82, 82)";
export const navbarInverseSearchBackgroundFocus = "#FFFFFF";
export const navbarInverseSearchBorder = "#111111";
export const navbarInverseSearchPlaceholderColor = "#CCCCCC";
export const navbarInverseBrandColor = "#999999";
export const paginationBackground = "#FFFFFF";
export const paginationBorder = "#DDDDDD";
export const paginationActiveBackground = "#F5F5F5";
export const heroUnitBackground = "#EEEEEE";
export const heroUnitHeadingColor = "inherit";
export const heroUnitLeadColor = "inherit";
export const warningText = "#C09853";
export const warningBackground = "#FCF8E3";
export const warningBorder = "rgb(251, 239, 213)";
export const errorText = "#B94A48";
export const errorBackground = "#F2DEDE";
export const errorBorder = "rgb(238, 211, 215)";
export const successText = "#468847";
export const successBackground = "#DFF0D8";
export const successBorder = "rgb(212, 232, 197)";
export const infoText = "#4A7C17";
export const infoBackground = "#EDF2DF";
export const infoBorder = "rgb(228, 232, 197)";
export const tooltipColor = "#FFFFFF";
export const tooltipBackground = "#000000";
export const tooltipArrowWidth = "5px";
export const tooltipArrowColor = "#000000";
export const popoverBackground = "#FFFFFF";
export const popoverArrowWidth = "10px";
export const popoverArrowColor = "#FFFFFF";
export const popoverTitleBackground = "rgb(247, 247, 247)";
export const popoverArrowOuterWidth = "11px";
export const popoverArrowOuterColor = "rgba(0, 0, 0, 0.25)";
export const gridColumns = "12";
export const gridColumnWidth = "60px";
export const gridGutterWidth = "20px";
export const gridRowWidth = "940px";
export const gridColumnWidth1200 = "70px";
export const gridGutterWidth1200 = "30px";
export const gridRowWidth1200 = "1170px";
export const gridColumnWidth768 = "42px";
export const gridGutterWidth768 = "20px";
export const gridRowWidth768 = "724px";
export const fluidGridColumnWidth = "6.382978723404255%";
export const fluidGridGutterWidth = "2.127659574468085%";
export const fluidGridColumnWidth1200 = "5.982905982905983%";
export const fluidGridGutterWidth1200 = "2.564102564102564%";
export const fluidGridColumnWidth768 = "5.801104972375691%";
export const fluidGridGutterWidth768 = "2.7624309392265194%";
export const kaGreen = "#71B307";
export const kaGreenLight = "#BED47A";
export const kaBlue = "#314453";
export const kaBlueLight = "#4D6779";
export const grayExtraDark = "#111111";
export const grayExtraLight = "#EEEEEE";
export const graySuperLight = "#F7F7F7";
export const whiteDark = "#FDFDFD";
export const dark = "#21242C";
export const blueDarkUnsaturated = "#3B414E";
export const blueDarkSaturated = "#1F3043";
export const blueLight = "#A9C0D1";
export const greenLight = "#C6D1AD";
export const greenDark = "#356700";
export const yellowGreen = "#9DB63B";
export const okGreen = "#A7CF5B";
export const alertRed = "#C42420";
export const coral = "#EE6666";
export const grayBlue = "#9AB3B9";
export const lightPageBackground = "#FDFDFD";
export const yclaBlue = "#49BAD5";
export const yclaTeal = "#56D0B3";
export const yclaTealDark = "#50C1A7";
export const yclaGreenDark = "#3D9A82";
export const yclaGreen = "#81C262";
export const learnstormBlue = "#4898FC";
export const homepageBlue = "#46A8BF";
export const backgroundBlue = "#00294A";
export const teacherSignupBlue = "#335d7d";
export const homepageGreen = "#2B8E7B";
export const streakColor = "#F79734";
export const facebookColor = "#1A60A2";
export const blueGoogleColor = "#2CAFE2";
export const redGoogleColor = "#BF4434";
export const homepageColor = "#2D585E";
export const lightTextColor = "#898989";
export const fontSizeTiny = "11px";
export const fontSizeNormal = "14px";
export const fontSizeMedium = "16px";
export const fontSizeExtraLarge = "24px";
export const fontSizeHuge = "36px";
export const fontSizeBakana = "48px";
export const fontSizeBananas = "60px";
export const fixedWidthFontFamily =
    "'Helvetica Neue',Helvetica,Arial,sans-serif";
export const contentPadding = "20px";
export const primaryButtonBackground = "#89B908";
export const primaryButtonBorder = "#76A005";
export const buttonTextColor = "rgb(122, 122, 122)";
export const buttonBorderRadius = "3px";
export const basicBorderColor = "#CCCCCC";
export const minContainerWidth = "1000px";
export const maxContainerWidth = "1200px";
export const formHintColor = "#999999";
export const guidelineExampleText = "#606060";
export const hoverCardWidth = "450px";
export const menuHighlightColor = "#96AB4F";
export const menuHighlightBorderColor = "#76A005";
export const menuActiveTextColor = "#FFFFFF";
export const menuInactiveTextColor = "#444444";
export const menuSubheaderTextColor = "rgb(120, 120, 120)";
export const menuBorderColor = "#94A170";
export const unreadBackgroundColor = "#ECEEF4";
export const menuLineHeight = "18px";
export const menuSubheaderLineHeight = "17px";
export const menuSubheaderFontSize = "9px";
export const notificationBarColor = "#4858A4";
export const modalBodyTopPadding = "20px";
export const modalDialogPadding = "15px";
export const modalSectionPadding = "9px";
export const mathDomain1 = "#11ACCD";
export const mathDomain2 = "#63D9EA";
export const mathDomain3 = "#027d97";
export const mathDomain4 = "#085566";
export const partnerContentDomain1 = "#01A995";
export const partnerContentDomain2 = "#01D1C1";
export const partnerContentDomain3 = "#208170";
export const partnerContentDomain4 = "#144F44";
export const csDomain1 = "#1FAB54";
export const csDomain2 = "#74CF70";
export const csDomain3 = "#0D923F";
export const csDomain4 = "#085E29";
export const economicsDomain1 = "#E07D10";
export const economicsDomain2 = "#FFBE26";
export const economicsDomain3 = "#A75A05";
export const economicsDomain4 = "#953C02";
export const collegeCareersMoreDomain1 = "#01A995";
export const collegeCareersMoreDomain2 = "#01D1C1";
export const collegeCareersMoreDomain3 = "#208170";
export const collegeCareersMoreDomain4 = "#144F44";
export const humanitiesDomain1 = "#E84D39";
export const humanitiesDomain2 = "#FF8482";
export const humanitiesDomain3 = "#BE2612";
export const humanitiesDomain4 = "#8C1C0D";
export const gtpDomain1 = "#1865F2";
export const gtpDomain2 = "#1865F2";
export const gtpDomain3 = "#1865F2";
export const gtpDomain4 = "#1865F2";
export const scienceDomain1 = "#CA337C";
export const scienceDomain2 = "#FF92C6";
export const scienceDomain3 = "#9E034E";
export const scienceDomain4 = "#6B0235";
export const testPrepDomain1 = "#1865F2";
export const testPrepDomain2 = "#1865F2";
export const testPrepDomain3 = "#1865F2";
export const testPrepDomain4 = "#1865F2";
export const defaultDomain1 = "#1865F2";
export const defaultDomain2 = "#66afe9";
export const defaultDomain3 = "#1152b0";
export const defaultDomain4 = "#0d3d82";
export const defaultDomainColor = "#314453";
export const defaultSubjectColor = "#4D6779";
export const defaultTopicColor = "#6A8DA6";
export const defaultHoverColor = "#314453";
export const defaultLinkColor = "#314453";
export const defaultLinkHoverColor = "#6A8DA6";
export const scienceDomainColor = "#94424F";
export const scienceSubjectColor = "#9D4A5A";
export const scienceTopicColor = "#C55F73";
export const scienceHoverColor = "#94424F";
export const scienceLinkColor = "#94424F";
export const scienceLinkHoverColor = "#C55F73";
export const humanitiesDomainColor = "#AD3434";
export const humanitiesSubjectColor = "#C13B31";
export const humanitiesTopicColor = "#D24A45";
export const humanitiesHoverColor = "#AD3434";
export const humanitiesLinkColor = "#AD3434";
export const humanitiesLinkHoverColor = "#D24A45";
export const economicsDomainColor = "#B77033";
export const economicsSubjectColor = "#BF7B34";
export const economicsTopicColor = "#D1933B";
export const economicsHoverColor = "#B77033";
export const economicsLinkColor = "#B77033";
export const economicsLinkHoverColor = "#D1933B";
export const csDomainColor = "#437A39";
export const csSubjectColor = "#53893E";
export const csTopicColor = "#689B51";
export const csHoverColor = "#437A39";
export const csLinkColor = "#437A39";
export const csLinkHoverColor = "#689B51";
export const partnerContentDomainColor = "#218270";
export const partnerContentSubjectColor = "#2C8D7B";
export const partnerContentTopicColor = "#329A86";
export const partnerContentHoverColor = "#218270";
export const partnerContentLinkColor = "#218270";
export const partnerContentLinkHoverColor = "#329A86";
export const essayDomainColor = "#0071B3";
export const mathDomainColor = "#1C758A";
export const mathSubjectColor = "#46A8BF";
export const mathTopicColor = "#4FBAD4";
export const mathHoverColor = "#1C758A";
export const mathLinkColor = "#1C758A";
export const mathLinkHoverColor = "#4FBAD4";
export const testPrepDomainColor = "#644172";
export const testPrepSubjectColor = "#7E5F8E";
export const testPrepTopicColor = "#9A72AC";
export const testPrepHoverColor = "#644172";
export const testPrepLinkColor = "#644172";
export const testPrepLinkHoverColor = "#9A72AC";
export const collegeCareersMoreDomainColor = "#218270";
export const collegeCareersMoreSubjectColor = "#2C8D7B";
export const collegeCareersMoreTopicColor = "#329A86";
export const collegeCareersMoreHoverColor = "#218270";
export const collegeCareersMoreLinkColor = "#218270";
export const collegeCareersMoreLinkHoverColor = "#329A86";
export const colorProficient = "#1C758A";
export const colorPracticed = "rgb(158, 221, 235)";
export const colorMastery1 = "rgb(90, 197, 221)";
export const colorMastery2 = "rgb(42, 174, 203)";
export const colorMastery3 = "#1C758A";
export const colorSuggested = "#73982C";
export const colorReview = "#E35D04";
export const colorNotStarted = "#CCCCCC";
export const colorStarted = "#C7E5F7";
export const colorStruggling = "#C30202";
export const colorPersonal = "#00809C";
export const colorPractice = "#96D9FF";
export const colorEnergyPoints = "#005987";
export const pureSmMin = "568px";
export const pureMdMin = "768px";
export const pureLgMin = "1024px";
export const pureXlMin = "1200px";
export const pureXsMax = "567px";
export const pureSmMax = "767px";
export const pureMdMax = "1023px";
export const pureLgMax = "1199px";
