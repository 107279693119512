// TODO(FEI-4010): Remove `Perseus` prefix for all types here
// TODO(FEI-4011): Use types generated by https://github.com/jaredly/generate-perseus-flowtypes

import type {Coord} from "./interactive2/types";
import type {Interval, vec} from "mafs";

// Range is replaced within this file with Interval, but it is used elsewhere
// and exported from the package, so we need to keep it around.
export type Range = Interval;
export type Size = [number, number];
export type CollinearTuple = readonly [vec.Vector2, vec.Vector2];
export type ShowSolutions = "all" | "selected" | "none";

// TODO(FEI-5054): Figure out how to get global .d.ts files working with monorepos
type Empty = Record<never, never>;

export type PerseusWidgetsMap = {
    [key in `categorizer ${number}`]: CategorizerWidget;
} & {
    [key in `cs-program ${number}`]: CSProgramWidget;
} & {
    [key in `definition ${number}`]: DefinitionWidget;
} & {
    [key in `dropdown ${number}`]: DropdownWidget;
} & {
    [key in `example-widget ${number}`]: ExampleWidget;
} & {
    [key in `example-graphie-widget ${number}`]: ExampleGraphieWidget;
} & {
    [key in `explanation ${number}`]: ExplanationWidget;
} & {
    [key in `expression ${number}`]: ExpressionWidget;
} & {
    [key in `grapher ${number}`]: GrapherWidget;
} & {
    [key in `group ${number}`]: GroupWidget;
} & {
    [key in `graded-group ${number}`]: GradedGroupWidget;
} & {
    [key in `graded-group-set ${number}`]: GradedGroupSetWidget;
} & {
    [key in `iframe ${number}`]: IFrameWidget;
} & {
    [key in `image ${number}`]: ImageWidget;
} & {
    [key in `input-number ${number}`]: InputNumberWidget;
} & {
    [key in `interaction ${number}`]: InteractionWidget;
} & {
    [key in `interactive-graph ${number}`]: InteractiveGraphWidget;
} & {
    [key in `label-image ${number}`]: LabelImageWidget;
} & {
    [key in `matcher ${number}`]: MatcherWidget;
} & {
    [key in `matrix ${number}`]: MatrixWidget;
} & {
    [key in `measurer ${number}`]: MeasurerWidget;
} & {
    [key in `molecule-renderer ${number}`]: MoleculeRendererWidget;
} & {
    [key in `number-line ${number}`]: NumberLineWidget;
} & {
    [key in `numeric-input ${number}`]: NumericInputWidget;
} & {
    [key in `orderer ${number}`]: OrdererWidget;
} & {
    [key in `passage ${number}`]: PassageWidget;
} & {
    [key in `passage-ref ${number}`]: PassageRefWidget;
} & {
    [key in `passage-ref-target ${number}`]: PassageRefWidget;
} & {
    [key in `plotter ${number}`]: PlotterWidget;
} & {
    [key in `python-program ${number}`]: PythonProgramWidget;
} & {
    [key in `radio ${number}`]: RadioWidget;
} & {
    [key in `simple-markdown-tester ${number}`]: SimpleMarkdownTesterWidget;
} & {
    [key in `sorter ${number}`]: SorterWidget;
} & {
    [key in `table ${number}`]: TableWidget;
} & {
    [key in `unit-input ${number}`]: UnitInputWidget;
} & {
    [key in `video ${number}`]: VideoWidget;
};

export type PerseusItem = {
    // The details of the question being asked to the user.
    question: PerseusRenderer;
    // A collection of hints to be offered to the user that support answering the question.
    hints: ReadonlyArray<PerseusRenderer>;
    // Details about the tools the user might need to answer the question
    answerArea: PerseusAnswerArea | null | undefined;
    // Multi-item should only show up in Test Prep content and it is a variant of a PerseusItem
    _multi: any;
    // The version of the item.  Not used by Perseus
    itemDataVersion: Version;
    // Deprecated field
    answer: any;
};

export type PerseusArticle = ReadonlyArray<PerseusRenderer>;

export type Version = {
    // The major part of the version
    major: number;
    // The minor part of the version
    minor: number;
};

export type PerseusRenderer = {
    // Translatable Markdown content to be rendered.  May include references to
    // widgets (as [[☃ widgetName]]) or images (as ![image text](imageUrl)).
    // For each image found in this content, there can be an entry in the
    // `images` dict (below) with the key being the image's url which defines
    // additional attributes for the image.
    content: string;
    // A dictionary of {[widgetName]: Widget} to be referenced from the content field
    widgets: PerseusWidgetsMap;
    // Used only for PerseusItem.hints.  If true, it replaces the previous hint in the list with the current one. This allows for hints that build upon each other.
    replace?: boolean;
    // Used in the PerseusGradedGroup widget.  A list of "tags" that are keys that represent other content in the system.  Not rendered to the user.
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    metadata?: ReadonlyArray<string>;
    // A dictionary of {[imageUrl]: PerseusImageDetail}.
    images: {
        [key: string]: PerseusImageDetail;
    };
};

export type PerseusImageDetail = {
    // The width of the image
    width: number;
    // the height of the image
    height: number;
};

export const ItemExtras = [
    // The user might benefit from using a Scientific Calculator.  Provided on Khan Academy when true
    "calculator",
    // The user might benefit from using a statistics Chi Squared Table like https://people.richland.edu/james/lecture/m170/tbl-chi.html
    "chi2Table",
    // The user might benefit from a monthly payments calculator.  Provided on Khan Academy when true
    "financialCalculatorMonthlyPayment",
    // The user might benefit from a total amount calculator.  Provided on Khan Academy when true
    "financialCalculatorTotalAmount",
    // The user might benefit from a time to pay off calculator.  Provided on Khan Academy when true
    "financialCalculatorTimeToPayOff",
    // The user might benefit from using a Periodic Table of Elements.  Provided on Khan Academy when true
    "periodicTable",
    // The user might benefit from using a Periodic Table of Elements with key.  Provided on Khan Academy when true
    "periodicTableWithKey",
    // The user might benefit from using a statistics T Table like https://www.statisticshowto.com/tables/t-distribution-table/
    "tTable",
    // The user might benefit from using a statistics Z Table like https://www.ztable.net/
    "zTable",
] as const;
export type PerseusAnswerArea = Record<(typeof ItemExtras)[number], boolean>;

type Widget<Type extends string, Options> = {
    // The "type" of widget which will define what the Options field looks like
    type: Type;
    // Whether this widget is displayed with the values and is immutable.  For display only
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    static?: boolean;
    // Whether a widget is scored.  Usually true except for IFrame widgets (deprecated)
    // Default: true
    graded?: boolean;
    // The HTML alignment of the widget.  "default" or "block"
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    alignment?: string;
    // Options specific to the type field of the widget.  See Perseus*WidgetOptions for more details
    options: Options;
    // Only used by interactive child widgets (line, point, etc) to identify the components
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    key?: number;
    // The version of the widget data spec.  Used to differentiate between newer and older content data.
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    version?: Version;
};

// prettier-ignore
export type CategorizerWidget = Widget<'categorizer', PerseusCategorizerWidgetOptions>;
// prettier-ignore
export type CSProgramWidget = Widget<'cs-program', PerseusCSProgramWidgetOptions>;
// prettier-ignore
export type DefinitionWidget = Widget<'definition', PerseusDefinitionWidgetOptions>;
// prettier-ignore
export type DropdownWidget = Widget<'dropdown', PerseusDropdownWidgetOptions>;
// prettier-ignore
export type ExplanationWidget = Widget<'explanation', PerseusExplanationWidgetOptions>;
// prettier-ignore
export type ExpressionWidget = Widget<'expression', PerseusExpressionWidgetOptions>;
// prettier-ignore
export type GradedGroupSetWidget = Widget<'graded-group-set', PerseusGradedGroupSetWidgetOptions>;
// prettier-ignore
export type GradedGroupWidget = Widget<'graded-group', PerseusGradedGroupWidgetOptions>;
// prettier-ignore
export type GrapherWidget = Widget<'grapher', PerseusGrapherWidgetOptions>;
// prettier-ignore
export type GroupWidget = Widget<'group', PerseusGroupWidgetOptions>;
// prettier-ignore
export type IFrameWidget = Widget<'iframe', PerseusIFrameWidgetOptions>;
// prettier-ignore
export type ImageWidget = Widget<'image', PerseusImageWidgetOptions>;
// prettier-ignore
export type InteractionWidget = Widget<'interaction', PerseusInteractionWidgetOptions>;
// prettier-ignore
export type InteractiveGraphWidget = Widget<'interactive-graph', PerseusInteractiveGraphWidgetOptions>;
// prettier-ignore
export type LabelImageWidget = Widget<'label-image', PerseusLabelImageWidgetOptions>;
// prettier-ignore
export type MatcherWidget = Widget<'matcher', PerseusMatcherWidgetOptions>;
// prettier-ignore
export type MatrixWidget = Widget<'matrix', PerseusMatrixWidgetOptions>;
// prettier-ignore
export type MeasurerWidget = Widget<'measurer', PerseusMeasurerWidgetOptions>;
// prettier-ignore
export type NumberLineWidget = Widget<'number-line', PerseusNumberLineWidgetOptions>;
// prettier-ignore
export type NumericInputWidget = Widget<'numeric-input', PerseusNumericInputWidgetOptions>;
// prettier-ignore
export type OrdererWidget = Widget<'orderer', PerseusOrdererWidgetOptions>;
// prettier-ignore
export type PassageRefWidget = Widget<'passage-ref', PerseusPassageRefWidgetOptions>;
// prettier-ignore
export type PassageWidget = Widget<'passage', PerseusPassageWidgetOptions>;
// prettier-ignore
export type PlotterWidget = Widget<'plotter', PerseusPlotterWidgetOptions>;
// prettier-ignore
export type PythonProgramWidget = Widget<'python-program', PerseusPythonProgramWidgetOptions>;
// prettier-ignore
export type RadioWidget = Widget<'radio', PerseusRadioWidgetOptions>;
// prettier-ignore
export type SorterWidget = Widget<'sorter', PerseusSorterWidgetOptions>;
// prettier-ignore
export type TableWidget = Widget<'table', PerseusTableWidgetOptions>;
// prettier-ignore
export type ExampleGraphieWidget = Widget<'example-graphie-widget', PerseusExampleGraphieWidgetOptions>;
// prettier-ignore
export type ExampleWidget = Widget<'example-widget', PerseusExampleWidgetOptions>;
// prettier-ignore
export type InputNumberWidget = Widget<'input-number', PerseusInputNumberWidgetOptions>;
// prettier-ignore
export type MoleculeRendererWidget = Widget<'molecule-renderer', PerseusMoleculeRendererWidgetOptions>;
// prettier-ignore
export type RefTargetWidget = Widget<'passage-ref-target', PerseusPassageRefTargetWidgetOptions>;
// prettier-ignore
export type SimpleMarkdownTesterWidget = Widget<'simple-markdown-tester', PerseusSimpleMarkdownTesterWidgetOptions>;
// prettier-ignore
export type UnitInputWidget = Widget<'unit-input', PerseusUnitInputWidgetOptions>;
// prettier-ignore
export type VideoWidget = Widget<'video', PerseusVideoWidgetOptions>;
//prettier-ignore
export type AutoCorrectWidget = Widget<'deprecated-standin', PerseusWidgetOptions>;

export type PerseusWidget =
    | CategorizerWidget
    | CSProgramWidget
    | DefinitionWidget
    | DropdownWidget
    | ExampleGraphieWidget
    | ExampleWidget
    | ExplanationWidget
    | ExpressionWidget
    | GradedGroupSetWidget
    | GradedGroupWidget
    | GrapherWidget
    | GroupWidget
    | IFrameWidget
    | ImageWidget
    | InputNumberWidget
    | InteractionWidget
    | InteractiveGraphWidget
    | LabelImageWidget
    | MatcherWidget
    | MatrixWidget
    | MeasurerWidget
    | MoleculeRendererWidget
    | NumberLineWidget
    | NumericInputWidget
    | OrdererWidget
    | PassageRefWidget
    | PassageWidget
    | PlotterWidget
    | PythonProgramWidget
    | RadioWidget
    | RefTargetWidget
    | SimpleMarkdownTesterWidget
    | SorterWidget
    | TableWidget
    | UnitInputWidget
    | VideoWidget
    | AutoCorrectWidget;

// A background image applied to various widgets.
export type PerseusImageBackground = {
    // The URL of the image
    url: string | null | undefined;
    // The width of the image
    width?: number;
    // The height of the image
    height?: number;
    // The top offset of the image
    // NOTE: perseus_data.go says this is required, but nullable, even though
    // it isn't necessary at all.
    top?: number;
    // The left offset of the image
    // NOTE: perseus_data.go says this is required, but nullable, even though
    // it isn't necessary at all.
    left?: number;
    // The scale of the image
    // NOTE: perseus_data.go says this is required, but nullable, even though
    // it isn't necessary at all.
    // Yikes, production data as this as both a number (1) and string ("1")
    scale?: number | string;
    // The bottom offset of the image
    // NOTE: perseus_data.go says this is required, but nullable, even though
    // it isn't necessary at all.
    bottom?: number;
};

export type PerseusCategorizerWidgetOptions = {
    // Translatable text; a list of items to categorize. e.g. ["banana", "yellow", "apple", "purple", "shirt"]
    items: ReadonlyArray<string>;
    // Translatable text; a list of categories. e.g. ["fruits", "colors", "clothing"]
    categories: ReadonlyArray<string>;
    // Whether the items should be randemized
    randomizeItems: boolean;
    // Whether this widget is displayed with the results and immutable
    static: boolean;
    // The correct answers where index relates to the items and value relates to the category.  e.g. [0, 1, 0, 1, 2]
    values: ReadonlyArray<number>;
    // Whether we should highlight i18n linter errors found on this widget
    highlightLint?: boolean;
    // Internal editor configuration. Can be ignored by consumers.
    linterContext?: PerseusLinterContext;
};

export type PerseusLinterContext = {
    contentType: string;
    paths: ReadonlyArray<string>;
    stack: ReadonlyArray<string>;
};

export type PerseusDefinitionWidgetOptions = {
    // Translatable text; the word to define. e.g. "vertex"
    togglePrompt: string;
    // Translatable text; the definition of the word. e.g. "where 2 rays connect"
    definition: string;
    // Always false. Not used for this widget
    static: boolean;
};

export type PerseusDropdownWidgetOptions = {
    // A list of choices for the dropdown
    choices: ReadonlyArray<PerseusDropdownChoice>;
    // Translatable Text; placeholder text for a dropdown. e.g. "Please select a fruit"
    placeholder: string;
    // Always false.  Not used for this widget
    static: boolean;
};

export type PerseusDropdownChoice = {
    // Translatable text; The text for the option. e.g. "Banana" or "Orange"
    content: string;
    // Whether this is the correct option or not
    correct: boolean;
};

export type PerseusExampleWidgetOptions = {
    value: string;
};

export type PerseusExampleGraphieGraph = {
    box: Size;
    range: [Coord, Coord];
    labels: ReadonlyArray<string>;
    markings: "graph" | "grid" | "none";
    gridStep: [number, number];
    step: [number, number];
    showProtractor?: boolean;
    showRuler?: boolean;
    valid?: boolean;
    backgroundImage?: PerseusImageBackground | null;
};

export type PerseusExampleGraphieWidgetOptions = {
    graph: PerseusExampleGraphieGraph;
    coord: [Coord, Coord] | null;
};

export type PerseusExplanationWidgetOptions = {
    // Translatable Text; The clickable text to expand an explanation.  e.g. "What is an apple?"
    showPrompt: string;
    // Translatable Text; The cliclable text to hide an explanation. e.g. "Thanks. I got it!"
    hidePrompt: string;
    // Translatable Markdown; The explanation that is shown when showPrompt is clicked.  e.g. "An apple is a tasty fruit."
    explanation: string;
    // explanation fields can embed widgets. When they do, the details of the widgets are here.
    widgets: PerseusWidgetsMap;
    // Always false.  Not used for this widget
    static: boolean;
};

export type LegacyButtonSets = ReadonlyArray<
    | "basic"
    | "basic+div"
    | "trig"
    | "prealgebra"
    | "logarithms"
    | "basic relations"
    | "advanced relations"
>;

export type PerseusExpressionWidgetOptions = {
    // The expression forms the answer may come in
    answerForms: ReadonlyArray<PerseusExpressionAnswerForm>;
    // Different buttons sets that can show in the expression. Options are "basic", "basic+div", "trig", "prealgebra", "logarithms", "basic relations", "advanced relations"
    buttonSets: LegacyButtonSets;
    // Variables that can be used as functions.  Default: ["f", "g", "h"]
    functions: ReadonlyArray<string>;
    // Use x for rendering multiplication instead of a center dot.
    times: boolean;
    // Controls when buttons for special characters are visible when using a
    // desktop browser.  Defaults to "focused".
    // NOTE: This isn't listed in perseus-format.js or perseus_data.go, but
    // appears in item data in the datastore.
    buttonsVisible?: "always" | "never" | "focused";
};

export const PerseusExpressionAnswerFormConsidered = [
    "correct",
    "wrong",
    "ungraded",
] as const;

export type PerseusExpressionAnswerForm = {
    // The Katex form of the expression.  e.g. "x\\cdot3=y"
    value: string;
    // The Answer expression must have the same form
    form: boolean;
    // The answer expression must be fully expanded and simplified
    simplify: boolean;
    // Whether the form is considered "correct", "wrong", or "ungraded"
    considered: (typeof PerseusExpressionAnswerFormConsidered)[number];
    // A key to identify the answer form in a list
    // NOTE: perseus-format.js says this is required even though it isn't necessary.
    key?: string;
};

export type PerseusGradedGroupWidgetOptions = {
    // Translatable Text; A title to be displayed for the group.
    title: string;
    // Not used in Perseus (but is set in (en, pt) production data)
    hasHint?: boolean | null | undefined;
    // A section to define hints for the group.
    hint?: PerseusRenderer | null | undefined;
    // Translatable Markdown. May include widgets and images embedded.
    content: string;
    // See PerseusRenderer.widgets
    widgets: PerseusWidgetsMap;
    // Not used in Perseus
    widgetEnabled?: boolean | null | undefined;
    // Not used in Perseus
    immutableWidgets?: boolean | null | undefined;
    // See PerseusRenderer.images
    images: {
        [key: string]: PerseusImageDetail;
    };
};

export type PerseusGradedGroupSetWidgetOptions = {
    // A list of Widget Groups
    gradedGroups: ReadonlyArray<PerseusGradedGroupWidgetOptions>;
};

// 2D range: xMin, xMax, yMin, yMax
export type GraphRange = [
    x: [min: number, max: number],
    y: [min: number, max: number],
];

export type PerseusGrapherWidgetOptions = {
    availableTypes: ReadonlyArray<
        | "absolute_value"
        | "exponential"
        | "linear"
        | "logarithm"
        | "quadratic"
        | "sinusoid"
        | "tangent"
    >;
    correct:
        | {
              type: "absolute_value";
              coords: [
                  // The vertex
                  Coord, // A point along one line of the absolute value "V" lines
                  Coord,
              ];
          }
        | {
              type: "exponential";
              // Two points along the asymptote line. Usually (always?) a
              // horizontal or vertical line.
              asymptote: [Coord, Coord];
              // Two points along the exponential curve. One end of the curve
              // trends towards the asymptote.
              coords: [Coord, Coord];
          }
        | {
              type: "linear";
              // Two points along the straight line
              coords: [Coord, Coord];
          }
        | {
              type: "logarithm";
              // Two points along the asymptote line.
              asymptote: [Coord, Coord];
              // Two points along the logarithmic curve. One end of the curve
              // trends towards the asymptote.
              coords: [Coord, Coord];
          }
        | {
              type: "quadratic";
              coords: [
                  // The vertex of the parabola
                  Coord, // A point along the parabola
                  Coord,
              ];
          }
        | {
              type: "sinusoid";
              // Two points on the same slope in the sinusoid wave line.
              coords: [Coord, Coord];
          }
        | {
              type: "tangent";
              // Two points on the same slope in the tangent wave line.
              coords: [Coord, Coord];
          };
    graph: {
        backgroundImage: {
            bottom?: number;
            height?: number;
            left?: number;
            scale?: number;
            url?: string | null | undefined;
            width?: number;
        };
        box?: [number, number];
        editableSettings?: ReadonlyArray<
            "graph" | "snap" | "image" | "measure"
        >;
        gridStep?: [number, number];
        labels: [string, string];
        markings: "graph" | "none" | "grid";
        range: GraphRange;
        rulerLabel: "";
        rulerTicks: number;
        showProtractor?: boolean;
        showRuler?: boolean;
        showTooltips?: boolean;
        snapStep?: [number, number];
        step: [number, number];
        valid?: boolean | string;
    };
};

export type PerseusGroupWidgetOptions = PerseusRenderer;

export type PerseusImageWidgetOptions = {
    // Translatable Markdown; Text to be shown for the title of the image
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    title?: string;
    // Translatable Markdown; Text to be shown in the caption section of an image
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    caption?: string;
    // Translatable Text; The alt text to be shown in the img.alt attribute
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    alt?: string;
    // The image details for the image to be displayed
    backgroundImage: PerseusImageBackground;
    // Always false.  Not used for this widget
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    static?: boolean;
    // A list of labels to display on the image
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    labels?: ReadonlyArray<PerseusImageLabel>;
    // The range on the image render for labels
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    range?: [Interval, Interval];
    // The box on the image render for labels. The same as backgroundImage.width and backgroundImage.height
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    box?: Size;
};

export type PerseusImageLabel = {
    // Translatable Text; The content of the label to display
    content: string;
    // The visual alignment of the label. default: "center"
    alignment: string;
    // The point on the image to display the label
    coordinates: ReadonlyArray<number>;
};

export type PerseusInteractiveGraphWidgetOptions = {
    // Where the little black axis lines & labels (ticks) should render.
    // Also known as the tick step. default [1, 1]
    // NOTE(kevinb): perseus_data.go defines this as Array<number>
    step: [number, number];
    // Where the grid lines on the graph will render. default [1, 1]
    // NOTE(kevinb): perseus_data.go defines this as Array<number>
    gridStep: [number, number];
    // Where the graph points will lock to when they are dragged. default [0.5, 0.5]
    // NOTE(kevinb): perseus_data.go defines this as Array<number>
    snapStep: [number, number];
    // An optional image to use in the background
    backgroundImage?: PerseusImageBackground;
    /**
     * The type of markings to display on the graph.
     * - graph: shows the axes and the grid lines
     * - grid: shows only the grid lines
     * - none: shows no markings
     */
    markings: "graph" | "grid" | "none";
    // How to label the X and Y axis.  default: ["x", "y"]
    labels: ReadonlyArray<string>;
    // Whether to show the Protractor tool overlayed on top of the graph
    showProtractor: boolean;
    // Whether to show the Ruler tool overlayed on top of the graph
    showRuler: boolean;
    // Whether to show tooltips on the graph
    showTooltips?: boolean;
    // The unit to show on the ruler.  e.g. "mm", "cm",  "m", "km", "in", "ft", "yd", "mi"
    rulerLabel: string;
    // How many ticks to show on the ruler.  e.g. 1, 2, 4, 8, 10, 16. Must be an integer.
    rulerTicks: number;
    // The X and Y coordinate ranges for the view of the graph.  default: [[-10, 10], [-10, 10]]
    // NOTE(kevinb): perseus_data.go defines this as Array<Array<number>>
    // TODO(kevinb): Add a transform function to interactive-graph.jsx to
    // rename `range` to `ranges` so that things are less confusing.
    range: GraphRange;
    // The type of graph
    graph: PerseusGraphType;
    // The correct kind of graph, if being used to select function type
    correct: PerseusGraphType;
    // Shapes (points, chords, etc) displayed on the graph that cannot
    // be moved by the user.
    lockedFigures?: ReadonlyArray<LockedFigure>;
};

const lockedFigureColorNames = [
    "green",
    "grayH",
    "purple",
    "pink",
    "red",
] as const;

export type LockedFigureColor = (typeof lockedFigureColorNames)[number];

export const lockedFigureColors: Record<LockedFigureColor, string> = {
    green: "#447A53",
    grayH: "#3B3D45",
    purple: "#594094",
    pink: "#B25071",
    red: "#D92916",
} as const;

export type LockedFigure = LockedPointType | LockedLineType;
export type LockedFigureType = LockedFigure["type"];

export type LockedPointType = {
    type: "point";
    coord: Coord;
    color: LockedFigureColor;
    filled: boolean;
};

export type LockedLineType = {
    type: "line";
    kind: "line" | "ray" | "segment";
    points: [point1: LockedPointType, point2: LockedPointType];
    color: LockedFigureColor;
    lineStyle: "solid" | "dashed";
    showPoint1: boolean;
    showPoint2: boolean;
};

export type PerseusGraphType =
    | PerseusGraphTypeAngle
    | PerseusGraphTypeCircle
    | PerseusGraphTypeLinear
    | PerseusGraphTypeLinearSystem
    | PerseusGraphTypePoint
    | PerseusGraphTypePolygon
    | PerseusGraphTypeQuadratic
    | PerseusGraphTypeRay
    | PerseusGraphTypeSegment
    | PerseusGraphTypeSinusoid;

export type PerseusGraphTypeCommon = {
    // NOTE(jeremy): This is referenced in the component. Verify if there's any
    // production data that still has this.
    coord?: Coord; // Legacy!
};

export type PerseusGraphTypeAngle = {
    type: "angle";
    // Whether to show the angle measurements.  default: false
    showAngles?: boolean;
    // Allow Reflex Angles if an "angle" type.  default: true
    allowReflexAngles?: boolean;
    // The angle offset in degrees if an "angle" type. default: 0
    angleOffsetDeg?: number;
    // Snap to degree increments if an "angle" type. default: 1
    snapDegrees?: number;
    // How to match the answer. If missing, defaults to exact matching.
    match?: "congruent";
    // must have 3 coords - ie [Coord, Coord, Coord]
    coords?: ReadonlyArray<Coord>;
};

export type PerseusGraphTypeCircle = {
    type: "circle";
    center?: Coord;
    radius?: number;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypeLinear = {
    type: "linear";
    // expects 2 coords
    coords?: CollinearTuple;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypeLinearSystem = {
    type: "linear-system";
    // expects 2 sets of 2 coords
    coords?: ReadonlyArray<CollinearTuple>;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypePoint = {
    type: "point";
    // The number of points if a "point" type.  default: 1.  "unlimited" if no limit
    numPoints?: number | "unlimited";
    coords?: ReadonlyArray<Coord>;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypePolygon = {
    type: "polygon";
    // The number of sides.  default: 3. "unlimited" if no limit
    numSides?: number | "unlimited";
    // Whether to the angle measurements.  default: false
    showAngles?: boolean;
    // Whether to show side measurements. default: false
    showSides?: boolean;
    // How to snap points.  e.g. "grid", "angles", or "sides"
    snapTo?: string;
    // How to match the answer. If missing, defaults to exact matching.
    match?: "similar" | "congruent" | "approx";
    coords?: ReadonlyArray<Coord>;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypeQuadratic = {
    type: "quadratic";
    // expects a list of 3 coords
    coords?: ReadonlyArray<Coord>;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypeSegment = {
    type: "segment";
    // The number of segments if a "segment" type. default: 1.  Max: 6
    numSegments?: number;
    // Expects a list of Coord tuples. Length should match the `numSegments` value.
    coords?: ReadonlyArray<CollinearTuple>;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypeSinusoid = {
    type: "sinusoid";
    // Expects a list of 2 Coords
    coords?: ReadonlyArray<Coord>;
} & PerseusGraphTypeCommon;

export type PerseusGraphTypeRay = {
    type: "ray";
    // Expects a list of 2 Coords
    coords?: CollinearTuple;
} & PerseusGraphTypeCommon;

export type PerseusLabelImageWidgetOptions = {
    // Translatable Text; Katex representation of choices
    choices: ReadonlyArray<string>;
    // The URL of the image
    imageUrl: string;
    // Translatable Text; To show up in the img.alt attribute
    imageAlt: string;
    // The height of the image
    imageHeight: number;
    // The width of the image
    imageWidth: number;
    // A list of markers to display on the image
    markers: ReadonlyArray<PerseusLabelImageMarker>;
    // Do not display answer choices in instructions
    hideChoicesFromInstructions: boolean;
    // Allow multiple answers per marker
    multipleAnswers: boolean;
    // Always false.  Not used for this widget
    static: boolean;
};

export type PerseusLabelImageMarker = {
    // A list of correct answers for this marker.  Often only one but can have multiple
    answers: ReadonlyArray<string>;
    // Translatable Text; The text to show for the marker. Not displayed directly to the user
    label: string;
    // X Coordiate location of the marker on the image
    x: number;
    // Y Coordinate location of the marker on the image
    y: number;
};

export type PerseusMatcherWidgetOptions = {
    // Translatable Text; Labels to adorn the headings for the columns.  Only 2 values [left, right]. e.g. ["Concepts", "Things"]
    labels: ReadonlyArray<string>;
    // Translatable Text; Static concepts to show in the left column. e.g. ["Fruit", "Color", "Clothes"]
    left: ReadonlyArray<string>;
    // Translatable Markup; Values that represent the concepts to be correlated with the concepts.  e.g. ["Red", "Shirt", "Banana"]
    right: ReadonlyArray<string>;
    // Order of the matched pairs matters. With this option enabled, only the order provided above will be treated as correct. This is useful when ordering is significant, such as in the context of a proof. If disabled, pairwise matching is sufficient. To make this clear, the left column becomes fixed in the provided order and only the cards in the right column can be moved.
    orderMatters: boolean;
    // Adds padding to the rows.  Padding is good for text, but not needed for images.
    padding: boolean;
};

export type PerseusMatrixWidgetAnswers = ReadonlyArray<ReadonlyArray<number>>;
export type PerseusMatrixWidgetOptions = {
    // Translatable Text; Shown before the matrix
    prefix: string;
    // Translatable Text; Shown after the matrix
    suffix: string;
    // A data matrix representing the "correct" answers to be entered into the matrix
    answers: PerseusMatrixWidgetAnswers;
    // The coordinate location of the cursor position at start. default: [0, 0]
    cursorPosition: ReadonlyArray<number>;
    // The coordinate size of the matrix.  Only supports 2-dimensional matrix.  default: [3, 3]
    matrixBoardSize: ReadonlyArray<number>;
    // Whether this is meant to statically display the answers (true) or be used as an input field, graded against the answers
    static: boolean;
};

export type PerseusMeasurerWidgetOptions = {
    // The image that the user is meant to measure
    image: PerseusImageBackground;
    // Whether to show the Protractor tool overlayed on top of the image
    showProtractor: boolean;
    // Whether to show the Ruler tool overlayed on top of the image
    showRuler: boolean;
    // The unit to show on the ruler.  e.g. "mm", "cm",  "m", "km", "in", "ft", "yd", "mi"
    rulerLabel: string;
    // How many ticks to show on the ruler.  e.g. 1, 2, 4, 8, 10, 16
    rulerTicks: number;
    // The number of image pixels per unit (label)
    rulerPixels: number;
    // The number of units to display on the ruler
    rulerLength: number;
    // Containing area [width, height]
    box: ReadonlyArray<number>;
    // Always false.  Not used for this widget
    static: boolean;
};

export type MathFormat =
    | "integer"
    | "mixed"
    | "improper"
    | "proper"
    | "decimal"
    | "percent"
    | "pi";

export type PerseusNumericInputAnswerForm = {
    simplify:
        | "required"
        | "correct"
        | "enforced"
        | "optional"
        | null
        | undefined;
    name: MathFormat;
};

export type PerseusNumericInputWidgetOptions = {
    // A list of all the possible correct and incorrect answers
    answers: ReadonlyArray<PerseusNumericInputAnswer>;
    // Translatable Text; Text to describe this input. This will be shown to users using screenreaders.
    labelText: string;
    // Use size "Normal" for all text boxes, unless there are multiple text boxes in one line and the answer area is too narrow to fit them. Options: "normal" or "small"
    size: string;
    // A coefficient style number allows the student to use - for -1 and an empty string to mean 1.
    coefficient: boolean;
    // Whether to right-align the text or not
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    rightAlign?: boolean;
    // Always false.  Not used for this widget
    static: boolean;
    // Used by examples, maybe not used and should be removed in the future
    // see TODO in numeric-input
    answerForms?: ReadonlyArray<PerseusNumericInputAnswerForm>;
};

export type PerseusNumericInputAnswer = {
    // Translatable Display; A description for why this answer is correct, wrong, or ungraded
    message: string;
    // The expected answer
    value: number;
    // Whether this answer is "correct", "wrong", or "ungraded"
    status: string;
    // The forms available for this answer.  Options: "integer, ""decimal", "proper", "improper", "mixed", or "pi"
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    answerForms?: ReadonlyArray<MathFormat>;
    // Whether we should check the answer strictly against the the configured answerForms (strict = true)
    // or include the set of default answerForms (strict = false).
    strict: boolean;
    // A range of error +/- the value
    // NOTE: perseus_data.go says this is non-nullable even though we handle null values.
    maxError: number | null | undefined;
    // Unsimplified answers are Ungraded, Accepted, or Wrong. Options: "required", "correct", or "enforced"
    simplify: string | null | undefined;
};

export type PerseusNumberLineWidgetOptions = {
    // The position of the endpoints of the number line. Setting the range constrains the position of the answer and the labels.
    range: ReadonlyArray<number>;
    // This controls the position of the left / right labels. By default, the labels are set by the range.  Note:  Ensure that the labels line up with the tick marks, or it may be confusing for users.
    labelRange: ReadonlyArray<number | null>;
    // This controls the styling of the labels for the two main labels as well as all the tick mark labels, if applicable. Options: "decimal", "improper", "mixed", "non-reduced"
    labelStyle: string;
    // Show label ticks
    labelTicks: boolean;
    // Show tick controller
    isTickCtrl?: boolean | null | undefined;
    // The range of divisions within the line
    divisionRange: ReadonlyArray<number>;
    // This controls the number (and position) of the tick marks. The number of divisions is constrained to the division range. Note:  The user will be able to specify the number of divisions in a number input.
    numDivisions: number | null | undefined;
    // This determines the number of different places the point will snap between two adjacent tick marks. Note: Ensure the required number of snap increments is provided to answer the question.
    snapDivisions: number;
    // This controls the number (and position) of the tick marks; you can either set the number of divisions (2 divisions would split the entire range in two halves), or the tick step (the distance between ticks) and the other value will be updated accordingly. Note:  There is no check to see if labels coordinate with the tick marks, which may be confusing for users if the blue labels and black ticks are off-step.
    tickStep: number | null | undefined;
    // The correct relative value. default: "eq". options: "eq", "lt", "gt", "le", "ge"
    correctRel: string | null | undefined;
    // This is the correct answer. The answer is validated (as right or wrong) by using only the end position of the point and the relation (=, &lt;, &gt;, ≤, ≥).
    correctX: number;
    // This controls the initial position of the point along the number line
    initialX: number | null | undefined;
    // Show tooltips
    showTooltip?: boolean;
    // When true, the answer is displayed and is immutable
    static: boolean;
};

export type PerseusOrdererWidgetOptions = {
    // All of the options available to the user. Place the cards in the correct order. The same card can be used more than once in the answer but will only be displayed once at the top of a stack of identical cards.
    options: ReadonlyArray<PerseusRenderer>;
    // The correct order of the options
    correctOptions: ReadonlyArray<PerseusRenderer>;
    // Cards that are not part of the answer
    otherOptions: ReadonlyArray<PerseusRenderer>;
    // "normal" for text options.  "auto" for image options.
    height: string;
    // Use the "horizontal" layout for short text and small images. The "vertical" layout is best for longer text (e.g. proofs).
    layout: string;
};

export type PerseusPassageWidgetOptions = {
    // Translatable Text; To add footnotes, add ^ characters where they belong in the passage. Then, add ^ in the footnotes area to reference the footnotes in the passage.
    footnotes: string;
    // Translatable Text; The text of the passage
    passageText: string;
    // translatableText - An optional title that will appear directly above the passage in the same font style. (e.g. Passage 1)
    passageTitle: string;
    // Should we show line numbers along with the passage?
    showLineNumbers: boolean;
    // Always false.  Not used for this widget
    static: boolean;
};

export type PerseusPassageRefWidgetOptions = {
    // The passage number
    passageNumber: number;
    // The reference number
    referenceNumber: number;
    // Short summary of the referenced section. This will be included in parentheses and quotes automatically.
    summaryText: string;
};

export const plotterPlotTypes = [
    "bar",
    "line",
    "pic",
    "histogram",
    "dotplot",
] as const;
export type PlotType = (typeof plotterPlotTypes)[number];

export type PerseusPlotterWidgetOptions = {
    // Translatable Text; The Axis labels. e.g. ["X Label", "Y Label"]
    labels: ReadonlyArray<string>;
    // Translatable Text; Categories to display along the X access.  e.g. [">0", ">6", ">12", ">18"]
    categories: ReadonlyArray<string>;
    // The type of the graph. options "bar", "line", "pic", "histogram", "dotplot"
    type: PlotType;
    // The maximimum Y tick to display in the graph
    maxY: number;
    // The scale of the Y Axis
    scaleY: number;
    // Which ticks to display the labels for. For instance, setting this to "4" will only show every 4th label (plus the last one)
    labelInterval: number | null | undefined;
    // Creates the specified number of divisions between the horizontal lines. Fewer snaps between lines makes the graph easier for the student to create correctly.
    snapsPerLine: number;
    // The Y values the graph should start with
    starting: ReadonlyArray<number>;
    // The Y values that represent the correct answer expected
    correct: ReadonlyArray<number>;
    // A picture to represent items in a graph.
    picUrl: string | null | undefined;
    // deprecated
    picSize: number | null | undefined;
    // deprecated
    picBoxHeight: number | null | undefined;
    // deprecated
    plotDimensions: ReadonlyArray<number>;
};

export type PerseusRadioWidgetOptions = {
    // The choices provided to the user.
    choices: ReadonlyArray<PerseusRadioChoice>;
    // Does this have a "none of the above" option?
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    hasNoneOfTheAbove?: boolean;
    // If multipleSelect is enabled, Specify the number expected to be correct.
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    countChoices?: boolean;
    // Randomize the order of the options or keep them as defined
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    randomize?: boolean;
    // Does this set allow for multiple selections to be correct?
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    multipleSelect?: boolean;
    // deprecated
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    deselectEnabled?: boolean;
    // deprecated
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    onePerLine?: boolean;
    // deprecated
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    displayCount?: any;
    // v0 props
    // `noneOfTheAbove` is still in use (but only set to `false`).
    noneOfTheAbove?: false;
};

export type PerseusRadioChoice = {
    // Translatable Markdown; The label for this choice
    content: string;
    // Translatable Markdown; A clue to give the user when they get it wrong
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    clue?: string;
    // Whether this option is a correct answer or not
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    correct?: boolean;
    // If this is none of the above, override the content with "None of the above"
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    isNoneOfTheAbove?: boolean;
    // deprecated
    // NOTE: perseus_data.go says this is required even though it isn't necessary.
    widgets?: PerseusWidgetsMap;
};

export type PerseusSequenceWidgetOptions = {
    // A list of Renderers to display in sequence
    json: ReadonlyArray<PerseusRenderer>;
};

export type PerseusSimulatorWidgetOptions = {
    // Translatable Text; The X Axis
    xAxisLabel: string;
    // Translatable Text; The Y Axis
    yAxisLabel: string;
    // Translatable Text; A lable to define the proportion of the simulation
    proportionLabel: string;
    // The type of simulation. options: "proportion", "percentage"
    proportionOrPercentage: string;
    // The number of times to run the simulation
    numTrials: number;
};

export type PerseusSorterWidgetOptions = {
    // Translatable Text; The correct answer (in the correct order). The user will see the cards in a randomized order.
    correct: ReadonlyArray<string>;
    // Adds padding to the options.  Padding is good for text but not needed for images
    padding: boolean;
    // Use the "horizontal" layout for short text and small images. The "vertical" layout is best for longer text and larger images.
    layout: string;
};

export type PerseusTableWidgetOptions = {
    // Translatable Text; A list of column headers
    headers: ReadonlyArray<string>;
    // The number of rows to display
    rows: number;
    // The number of columns to display
    columns: number;
    // Translatable Text; A 2-dimensional array of text to populate the table with
    answers: ReadonlyArray<ReadonlyArray<string>>;
};

export type DilationTransformation = {
    type: "dilation";
    center: Coord;
    scale: number;
    constraints: {
        fixed: boolean;
    };
};

export type ReflectionTransformation = {
    type: "reflection";
    line: [Coord, Coord];
    constraints?: {
        fixed: boolean;
    };
};

export type RotationTransformation = {
    type: "rotation";
    angleDeg: number;
    center: Coord;
    constraints: {
        fixed: boolean;
    };
};

export type TranslationTransformation = {
    type: "translation";
    vector: Coord;
    contraints: Empty;
};

export type PerseusInteractionWidgetOptions = {
    // The definition of the graph
    graph: PerseusInteractionGraph;
    // The elements of the graph
    elements: ReadonlyArray<PerseusInteractionElement>;
    // Always false.  Not used for this widget
    static: boolean;
};

export type PerseusInteractionGraph = {
    // "canvas", "graph"
    editableSettings?: ReadonlyArray<"canvas" | "graph">;
    // The Grid Canvas size. e.g. [400, 140]
    box: Size;
    // The Axis labels.  e.g. ["x", "y"]
    labels: ReadonlyArray<string>;
    // The Axis ranges. e.g. [[-10, 10], [-10, 10]]
    range: [Interval, Interval];
    // The steps in the grid. default [1, 1]
    gridStep: [number, number];
    /**
     * The type of markings to display on the graph.
     * - graph: shows the axes and the grid lines
     * - grid: shows only the grid lines
     * - none: shows no markings
     */
    markings: "graph" | "grid" | "none";
    // The snap steps. default [0.5, 0.5]
    snapStep?: [number, number];
    // Whether the grid is valid or not.  Do the numbers all make sense?
    // NOTE(jeremy) The editor for this widget sometimes stores the graph
    // editor validation error message into this field. It seems innocuous
    // because it looks like many of these usages don't actually use the graph
    // at all.
    valid?: boolean | string;
    // An optional background image to use
    backgroundImage?: PerseusImageBackground;
    // Whether to show the Protractor tool overlayed on top of the graph
    showProtractor?: boolean;
    // Whether to show the Ruler tool overlayed on top of the graph
    showRuler?: boolean;
    // The unit to show on the ruler.  e.g. "mm", "cm",  "m", "km", "in", "ft", "yd", "mi"
    rulerLabel?: string;
    // How many ticks to show on the ruler.  e.g. 1, 2, 4, 8, 10, 16
    rulerTicks?: number;
    // This controls the number (and position) of the tick marks for the X and Y axis. e.g. [1, 1]
    tickStep: [number, number];
};

export type PerseusInteractionElement =
    | {
          type: "function";
          // An identifier for the element
          key: string;
          options: PerseusInteractionFunctionElementOptions;
      }
    | {
          type: "label";
          // An identifier for the element
          key: string;
          options: PerseusInteractionLabelElementOptions;
      }
    | {
          type: "line";
          // An identifier for the element
          key: string;
          options: PerseusInteractionLineElementOptions;
      }
    | {
          type: "movable-line";
          // An identifier for the element
          key: string;
          options: PerseusInteractionMovableLineElementOptions;
      }
    | {
          type: "movable-point";
          // An identifier for the element
          key: string;
          options: PerseusInteractionMovablePointElementOptions;
      }
    | {
          type: "parametric";
          // An identifier for the element
          key: string;
          options: PerseusInteractionParametricElementOptions;
      }
    | {
          type: "point";
          // An identifier for the element
          key: string;
          options: PerseusInteractionPointElementOptions;
      }
    | {
          type: "rectangle";
          // An identifier for the element
          key: string;
          options: PerseusInteractionRectangleElementOptions;
      };

export type PerseusInteractionFunctionElementOptions = {
    // The definition of the function to draw on the graph.  e.g "x^2 + 1"
    value: string;
    // The name of the function like f(n). default: "f"
    funcName: string;
    // The range of points to start plotting
    rangeMin: string;
    // The range of points to end plotting
    rangeMax: string;
    // The color of the stroke. e.g. #6495ED
    color: string;
    // If the function stroke has a dash, what is it? options: "", "-", "- ", ".", ". "
    strokeDasharray: string;
    // The thickness of the stroke
    strokeWidth: number;
};

export type PerseusInteractionLabelElementOptions = {
    // Translatable Text; the content of the label
    label: string;
    // The color of the label.  e.g. "red"
    color: string;
    // The X location of the label
    coordX: string;
    // The Y location of the label
    coordY: string;
};

export type PerseusInteractionLineElementOptions = {
    // A color code for the line segment.  e.g. "#FFOOAF"
    color: string;
    // The start of the line segment (X)
    startX: string;
    // The start of the line segment (Y)
    startY: string;
    // The end of the line segment (X)
    endX: string;
    // The end of the line segment (Y)
    endY: string;
    // If the line stroke has a dash, what is it? options: "", "-", "- ", ".", ". "
    strokeDasharray: string;
    // The thickness of the line
    strokeWidth: number;
    // Does the line have an arrow point to it? options: "", "->"
    arrows: string;
};

export type PerseusInteractionMovableLineElementOptions = {
    // The start of the line segment (X)
    startX: string;
    // The start of the line segment (Y)
    startY: string;
    // Start updates (Xn, Yn) for n
    startSubscript: number;
    // The end of the line segment (X)
    endX: string;
    // The end of the line segment (Y)
    endY: string;
    // End updates (Xm, Ym) for m
    endSubscript: number;
    // How to constrain this line? options "none", "snap", "x", "y"
    constraint: string;
    // The snap resolution when constraint is set to "snap"
    snap: number;
    // The constraint function for when constraint is set to "x" or "y"
    constraintFn: string;
    // The lowest possible X value
    constraintXMin: string;
    // The highest possible X value
    constraintXMax: string;
    // The lowest possible Y value
    constraintYMin: string;
    // The highest possible Y value
    constraintYMax: string;
};

export type PerseusInteractionMovablePointElementOptions = {
    // The X position of the point
    startX: string;
    // The Y position of the point
    startY: string;
    // Update (Xn, Yn) for n
    varSubscript: number;
    // How to constrain this line? options "none", "snap", "x", "y"
    constraint: string;
    // The snap resolution when constraint is set to "snap"
    snap: number;
    // The constraint function for when constraint is set to "x" or "y"
    constraintFn: string;
    // The lowest possible X value
    constraintXMin: string;
    // The highest possible X value
    constraintXMax: string;
    // The lowest possible Y value
    constraintYMin: string;
    // The highest possible Y value
    constraintYMax: string;
};

export type PerseusInteractionParametricElementOptions = {
    // The function for the X coordinate. e.g. "\\cos(t)"
    x: string;
    // The function for the Y coordinate. e.g. "\\sin(t)"
    y: string;
    // The range of points to start plotting
    rangeMin: string;
    // The range of points to end plotting
    rangeMax: string;
    // The color of the stroke. e.g. #6495ED
    color: string;
    // If the function stroke has a dash, what is it? options: "", "-", "- ", ".", ". "
    strokeDasharray: string;
    // The thickness of the stroke
    strokeWidth: number;
};

export type PerseusInteractionPointElementOptions = {
    // The color of the point.  e.g. "black"
    color: string;
    // The X coordinate of the point
    coordX: string;
    // The Y coordinate of the point
    coordY: string;
};

export type PerseusInteractionRectangleElementOptions = {
    // The fill color.  e.g. "#EDD19B"
    color: string;
    // The lower left point X
    coordX: string;
    // The lower left point Y
    coordY: string;
    // The width of the rectangle
    width: string;
    // The height of the rectangle
    height: string;
};

export type PerseusCSProgramWidgetOptions = {
    // The ID of the CS program to embed
    programID: string;
    // Deprecated.  Always null and sometimes omitted entirely.
    programType?: any;
    // Settings that you add here are available to the program as an object returned by Program.settings()
    settings: ReadonlyArray<PerseusCSProgramSetting>;
    // If you show the editor, you should use the "full-width" alignment to make room for the width of the editor.
    showEditor: boolean;
    // Whether to show the execute buttons
    showButtons: boolean;
    // The width of the widget
    width: number;
    // The height of the widget
    height: number;
    // Always false
    static: boolean;
};

export type PerseusCSProgramSetting = {
    // The name/key of the setting
    name: string;
    // The value of the setting
    value: string;
};

export type PerseusPythonProgramWidgetOptions = {
    // The ID of the Python program to embed
    programID: string;
    // The height of the widget in pixels
    height: number;
};

export type PerseusIFrameWidgetOptions = {
    // A URL to display OR a CS Program ID
    url: string;
    // Settings that you add here are available to the program as an object returned by Program.settings()
    settings: ReadonlyArray<PerseusCSProgramSetting>;
    // The width of the widget
    width: number | string;
    // The height of the widget
    height: number | string;
    // Whether to allow the IFrame to become full-screen (like a video)
    allowFullScreen: boolean;
    // Whether to allow the iframe content to redirect the page
    allowTopNavigation?: boolean;
    // Always false
    static: boolean;
};

export type PerseusVideoWidgetOptions = {
    location: string;
    static?: boolean;
};

export type PerseusInputNumberWidgetOptions = {
    answerType?:
        | "number"
        | "decimal"
        | "integer"
        | "rational"
        | "improper"
        | "mixed"
        | "percent"
        | "pi";
    inexact?: boolean;
    maxError?: number | string;
    rightAlign?: boolean;
    simplify: "required" | "optional" | "enforced";
    size: "normal" | "small";
    value: string | number;
    customKeypad?: boolean;
};

export type PerseusMoleculeRendererWidgetOptions = {
    widgetId: string;
    rotationAngle?: number;
    smiles?: string;
};

export type PerseusPassageRefTargetWidgetOptions = {
    content: string;
};

export type PerseusSimpleMarkdownTesterWidgetOptions = {
    value: string;
};
export type PerseusUnitInputWidgetOptions = {
    value: string;
};

export type PerseusWidgetOptions =
    | PerseusCategorizerWidgetOptions
    | PerseusCSProgramWidgetOptions
    | PerseusDefinitionWidgetOptions
    | PerseusDropdownWidgetOptions
    | PerseusExampleGraphieWidgetOptions
    | PerseusExampleWidgetOptions
    | PerseusExplanationWidgetOptions
    | PerseusExpressionWidgetOptions
    | PerseusGradedGroupSetWidgetOptions
    | PerseusGradedGroupWidgetOptions
    | PerseusIFrameWidgetOptions
    | PerseusImageWidgetOptions
    | PerseusInputNumberWidgetOptions
    | PerseusInteractionWidgetOptions
    | PerseusInteractiveGraphWidgetOptions
    | PerseusLabelImageWidgetOptions
    | PerseusMatcherWidgetOptions
    | PerseusMatrixWidgetOptions
    | PerseusMeasurerWidgetOptions
    | PerseusMoleculeRendererWidgetOptions
    | PerseusNumberLineWidgetOptions
    | PerseusNumericInputWidgetOptions
    | PerseusOrdererWidgetOptions
    | PerseusPassageRefTargetWidgetOptions
    | PerseusPassageRefWidgetOptions
    | PerseusPassageWidgetOptions
    | PerseusPlotterWidgetOptions
    | PerseusRadioWidgetOptions
    | PerseusSimpleMarkdownTesterWidgetOptions
    | PerseusSorterWidgetOptions
    | PerseusTableWidgetOptions
    | PerseusUnitInputWidgetOptions
    | PerseusVideoWidgetOptions;
